import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

function Home() {
  return (
    <div className="home">
      <Typography variant="h4" align="center" padding={3} color="darkblue">
        Welcome to the Admin Console
      </Typography>
    </div>
  );
}

export default Home;
