import React, { useState, useEffect } from "react";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import {
  withAuthenticator,
  useAuthenticator,
  ScrollView,
} from "@aws-amplify/ui-react";
import Environments from "./Environments";
import Home from "./Home";
import ReportEmbedActivity from "./ReportEmbedActivity";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Logout from "./Logout";
function MainNav() {
  //main view
  return (
    <div
      id="app"
      style={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Navbar />
      <ScrollView style={{ padding: "10px", width: "100%" }}>
        <Routes>
          <Route path="/admin" element={<Home />} />
          <Route path="/environments" element={<Environments />} />
          <Route path="/activity" element={<ReportEmbedActivity />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<div></div>} />
        </Routes>
      </ScrollView>
    </div>
  );
}

export default MainNav;
