import React, { useState, useEffect } from "react";
import "../App.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import StorageIcon from "@mui/icons-material/Storage";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import AppsIcon from "@mui/icons-material/Apps";

function Navbar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [forceRender, setForceRender] = useState(0);

  useEffect(() => {
    //eslint-disable-next-line
  }, [forceRender]);

  const refreshHome = () => {
    setForceRender((value) => value + 1);
  };

  return (
    <Sidebar
      // breakPoint="all"
      transitionDuration={800}
      backgroundColor="steelblue"
      rtl={false}
      width="18%"
      collapsed={isCollapsed}
    >
      <Menu>
        <MenuItem
          icon={<AppsIcon />}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
          style={{ textAlign: "center" }}
        ></MenuItem>
        <MenuItem
          icon={
            <Tooltip title="Home" placement="right">
              <HomeIcon />
            </Tooltip>
          }
          onClick={() => refreshHome()}
          component={<NavLink to="/admin" />}
        >
          Home
        </MenuItem>
        <MenuItem
          icon={
            <Tooltip title="Environments" placement="right">
              <StorageIcon />
            </Tooltip>
          }
          component={<NavLink to="/environments" />}
        >
          Environments
        </MenuItem>
        <MenuItem
          icon={
            <Tooltip title="Report Embed Activity" placement="right">
              <ChangeHistoryIcon />
            </Tooltip>
          }
          component={<NavLink to="/activity" />}
        >
          Report Embed Activity
        </MenuItem>

        <MenuItem
          icon={
            <Tooltip title="Sign Out" placement="right">
              <LogoutIcon />
            </Tooltip>
          }
          component={<NavLink to="/logout" />}
        >
          Sign out
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default Navbar;
