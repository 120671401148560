/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "powerbiapi",
            "endpoint": "https://wadju0j2r8.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:dc2a35d1-0495-48e2-941e-9a84a315a0f7",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_VCAU70Jde",
    "aws_user_pools_web_client_id": "1sjmvmv04dsgsv5aca7sn73r2r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Environments-staging",
            "region": "us-east-2"
        },
        {
            "tableName": "ReportEmbedActivity-staging",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
