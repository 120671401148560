import { useState, useEffect, useRef } from "react";

import { post } from "aws-amplify/api";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react";
import { Alert, Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

let queryString;
const refreshWaitSeconds = 15;

const EmbedReport = () => {
  const [error, setError] = useState("");
  const [response, setResponse] = useState();
  const [report, setReport] = useState(null);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [refreshWaitTime, setRefreshWaitTime] = useState(refreshWaitSeconds);
  const isReportLoadingRef = useRef(false);

  useEffect(() => {
    if (!queryString) {
      const url = new URL(window.location.href);
      queryString = new URLSearchParams(url.search);
    }

    let json;
    let errorMessage = "";

    try {
      json = {
        reportId: queryString.get("report_code"),
        sessionId: queryString.get("session"),
        environmentId: queryString.get("environment"),
      };

      if (!json.reportId) {
        errorMessage = "Invalid Report ID";
      } else if (!json.sessionId) {
        errorMessage = "Invalid Session ID";
      } else if (!json.environmentId) {
        errorMessage = "Invalid Environment ID";
      }
    } catch {
      errorMessage = "Invalid JSON parameters";
    }

    if (!errorMessage && !response && !isReportLoadingRef.current) {
      getEmbedReport(json)
        .then((result) => {
          setResponse(result);
        })
        .catch((error, ex) => {
          console.log("POST call failed: ", ex);
          setError(error);
        });
    } else {
      setError(errorMessage);
    }
  }, [setResponse]);

  const getEmbedReport = async (params) => {
    isReportLoadingRef.current = true;
    const promise = new Promise(async (resolve, reject) => {
      try {
        const restOperation = post({
          apiName: "powerbiapi",
          path: "/getembedtoken",
          options: {
            headers: {
              Authorization:
                params.sessionId +
                "@" +
                params.environmentId +
                "@" +
                params.reportId,
            }
          },
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        isReportLoadingRef.current = false;
        resolve(response);
      } catch (ex) {
        isReportLoadingRef.current = false;

        const error = {
          code: ex.response.statusCode,
          message: JSON.parse(ex.response.body).message
        }
        
        reject(error, ex);
      }
    });

    return promise;
  };

  const handlePrintOnClick = (e, option) => {
    report.print();
  };

  const handleRefreshOnClick = (e, option) => {
    setRefreshWaitTime(-1);
    let firstLoad = true;
    report
      .refresh()
      .then(() => {
        const intervalId = setInterval(() => {
          if(firstLoad) {
            setRefreshWaitTime(refreshWaitSeconds);
            firstLoad = false;
          }

          setRefreshWaitTime((prevState) => {
            if (prevState === 1) {
              clearInterval(intervalId);
              setRefreshWaitTime(refreshWaitSeconds);
            }

            return prevState - 1;
          });
        }, 1000);
      })
      .catch((error) => {
        if (error.message === "refreshNotAllowed") {
          handleRefreshOnClick();
        }
      });
  };

  const handleReportLoaded = () => {
    setReportLoaded(true);
  };

  const handleMaximizeOnClick = () => {
    report.fullscreen();
  };

  const handleOpenInNewTabOnClick = () => {
    window.open(window.location.href,'_blank');
  };

  const formatError = (error) => {
    if(typeof error === 'string') {
      return error;
    }

    if(error.code === 401 || error.message.toLowerCase() === 'unauthorized') {
      return 'You are not authorized to see this report';
    }

    return error.message;
  }

  if (error) {
    const UImessage = formatError(error);

    return (
      <div className="rego-error-alert-container">
        <div className="rego-error-alert">
          <Alert
            message={UImessage}
            type="error"
            showIcon
            closable
          />
        </div>
      </div>
    );
  }

  if (!response) {
    return (
      <Flex className="rego-rhbi-loading">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} />
      </Flex>
    );
  }

  if(refreshWaitTime === 0) {
    setRefreshWaitTime(refreshWaitSeconds);
  }

  const filters = [];

  const queryStringFilter = queryString.get("filter");

  if(queryStringFilter) {
    const items = queryStringFilter.substring(1,queryStringFilter.length - 1) .split(/ and | or /);
    let filter;
    let target;
    let value;

    items.forEach(item => {
      filter = item.split(' eq ');

      if(filter && filter.length === 2) {
        target = filter[0].split('/');

        if(target && target.length === 2) {
          value = isNaN(filter[1]) ? String(filter[1]) : Number(filter[1]);

          filters.push({
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
              table: target[0],
              column: target[1]
            },
            operator: "Eq",
            values: [value],
            filterType: 1
          });
        }
      }
    });
  }
  
  return (
    <div id="report-container">
      <div
        className="rego-actions-bar"
        style={{
          display: reportLoaded ? "block" : "none",
          textAlign: "center",
        }}
      >
        <div style={{ float: "left" }}>
          <DefaultButton
            onClick={handlePrintOnClick}
            className="rego-button-icon rego-print-icon"
          >
            <span>&nbsp;</span>
          </DefaultButton>
          <DefaultButton
            style={{ marginLeft: 5, color: 'gray'}}
            disabled={refreshWaitTime !== refreshWaitSeconds}
            onClick={handleRefreshOnClick}
            className={
              refreshWaitTime === refreshWaitSeconds
                ? "rego-button-icon rego-refresh-icon"
                : "rego-button-icon rego-clock-icon"//rego-refresh-icon-disabled"
            }
          >
            <span style={{ paddingLeft: '10px'}}>{refreshWaitTime !== refreshWaitSeconds ? refreshWaitTime === -1 ? 15 : refreshWaitTime : ''}&nbsp;</span>
          </DefaultButton>
        </div>
        <div className="rego-report-name">
          <Label className="rego-report-name-label">
            {response.embedReports[0].reportName}
          </Label>
        </div>
        <div style={{ float: "right" }}>
          <DefaultButton
            style={{ marginLeft: 5 }}
            onClick={handleMaximizeOnClick}
            className="rego-button-icon rego-maximize-icon"
          />
        </div>
        <div style={{ float: "right" }}>
          <DefaultButton
            onClick={handleOpenInNewTabOnClick}
            className="rego-button-icon rego-new-tab-icon"
          />
        </div>
      </div>
      <PowerBIEmbed
        id="report"
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS (embed for org: models.TokenType.Aad)
          accessToken: response.embedToken.token,
          embedUrl: response.embedReports[0].embedUrl,
          id: response.embedReports[0].reportId,
          permissions: models.Permissions.All,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true,
          },
          filters
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              function () {
                handleReportLoaded();
              },
            ],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ],
            [
              "visualClicked",
              () => {
                console.log("visual clicked");
              },
            ],
            [
              "pageChanged",
              (event) => {
                console.log(event);
              },
            ],
          ])
        }
        cssClassName={"reportClass"}
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport);
        }}
      />
    </div>
  );
};

export default EmbedReport;
