import React, { useState } from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { post } from "aws-amplify/api";
import {
  Button,
  Flex,
  TextAreaField,
  TextField,
  View,
  CheckboxField,
} from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { fetchAuthSession } from "aws-amplify/auth";

const EnvironmentCreate = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  async function createEnvironment(event) {
    setIsSaving(true);
    event.preventDefault();
    const form = new FormData(event.target);
    let is_active = false;
    if (form.get("active")) {
      is_active = true;
    }
    const data = {
      domainname: form.get("domainname"),
      pbiworkspaceid: form.get("pbiworkspaceid"),
      customeralias: form.get("customeralias"),
      active: is_active,
      maxrunsperhour: Number(form.get("maxrunsperhour")),
      maxrunsperday: Number(form.get("maxrunsperday")),
    };
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const restOperation = post({
        apiName: "powerbiapi",
        path: "/environments",
        options: {
          headers: {
            Authorization: authToken,
          },
          body: data,
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log("POST call succeeded");
      console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
    }
    props.handleCloseModal();
    props.forceRender();
    event.target.reset();
    setIsSaving(false);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Create New Environment
      </Typography>
      <View as="form" margin="1rem 0" onSubmit={createEnvironment}>
        <Flex direction="column" justifyContent="left">
          <TextField
            name="domainname"
            label="Domain"
            size="small"
            required
            type="url"
          />
          <TextField
            name="pbiworkspaceid"
            label="Power BI Workspace ID"
            size="small"
            required
          />
          <Flex direction="row" justifyContent="left">
            <TextField
              name="customeralias"
              label="Name"
              size="small"
              required
            />
            <CheckboxField
              name="active"
              label="Is Active?"
              size="small"
              labelPosition="top"
              defaultChecked
              value="yes"
            />
          </Flex>
          <Flex direction="row" justifyContent="left">
            <TextField
              name="maxrunsperhour"
              label="Max Runs Per Hour"
              type="number"
              size="small"
              required
            />
            <TextField
              name="maxrunsperday"
              label="Max Runs Per Day"
              type="number"
              size="small"
              required
            />
          </Flex>
        </Flex>

        <Flex direction="row" margin="1rem 0" justifyContent="center">
          <Button
            type="submit"
            variation="primary"
            size="small"
            width="20%"
            isLoading={isSaving}
          >
            Save
          </Button>
          <Button
            type="button"
            variation="warning"
            size="small"
            width="20%"
            // onClick={props.handleCloseModal}
            onClick={() => props.handleCloseModal()}
          >
            Close
          </Button>
        </Flex>
      </View>
    </Box>
  );
};

export default EnvironmentCreate;
