import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  RECORDS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
  MAX_RECORDS_LIMIT,
  DEFAULT_PAGE_SIZE,
} from "../regoutil";
import { get } from "aws-amplify/api";
import { Box, Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const ReportEmbedActivityTable = () => {
  const [activity, setActivity] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [nextApiToken, setNextApiToken] = useState(null);
  const [jsonFilter, setJsonFilter] = useState({});

  var variables = {
    limit: RECORDS_PER_PAGE,
    nextToken: nextApiToken,
    jsonFilter: encodeURIComponent(JSON.stringify(jsonFilter)),
  };

  const [isLoading, setIsLoading] = useState(true);
  const [forceRender, setForceRender] = useState(0);
  const [columnFilters, setColumnFilters] = useState([
    { id: "status", value: "SUCCESS" },
    {
      id: "requesteddate",
      value: [dayjs().subtract(1, "day"), dayjs()],
    },
  ]);

  const defaultStringFilterModes = [
    "equals",
    "contains",
    "startsWith",
    "endsWith",
    // "empty",
    // "notEmpty",
  ];
  const defaultNumberFilterModes = [
    "equals",
    "betweenInclusive",
    // "empty",
    // "notEmpty",
    "greaterThan",
    "greaterThanOrEqualTo",
    "lessThan",
    "lessThanOrEqualTo",
  ];
  const defaultDateFilterModes = [
    "equals",
    "betweenInclusive",
    // "empty",
    // "notEmpty",
    "greaterThan",
    "greaterThanOrEqualTo",
    "lessThan",
    "lessThanOrEqualTo",
  ];

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "envid",
        header: "Environment",
        columnFilterModeOptions: ["equals"],
        filterFn: "equals",
        filterVariant: "autocomplete",
        filterSelectOptions: environments,
        size: 150,
      },
      {
        accessorFn: (row) => new Date(row.requesteddate),
        id: "requesteddate",
        header: "Requested Date",
        Cell: ({ cell }) =>
          `${cell.getValue().toLocaleDateString()} ${cell
            .getValue()
            .toLocaleTimeString()}`,
        columnFilterModeOptions: ["betweenInclusive"],
        filterVariant: "datetime-range",
        size: 400,
      },
      {
        accessorKey: "status",
        header: "Status",
        // columnFilterModeOptions: defaultStringFilterModes,
        filterVariant: "select",
        filterSelectOptions: ["SUCCESS", "FAILURE"],
        size: 150,
      },
      {
        accessorKey: "executiontime",
        header: "Execution Time (ms)",
        enableColumnFilter: false,
        // columnFilterModeOptions: defaultNumberFilterModes,
        // filterFn: "betweenInclusive",
        size: 150,
      },
      {
        accessorKey: "requestid",
        header: "Request ID",
        enableColumnFilter: false,
        // columnFilterModeOptions: defaultStringFilterModes,
        // filterFn: "equals",
        size: 200,
      },
      {
        accessorKey: "username",
        header: "BI Username",
        enableColumnFilter: false,
        // columnFilterModeOptions: defaultStringFilterModes,
        // filterFn: "equals",
        size: 200,
      },
      {
        accessorKey: "workspaceid",
        header: "Workspace ID",
        enableColumnFilter: false,
        // columnFilterModeOptions: defaultStringFilterModes,
        // filterFn: "equals",
        size: 200,
      },
      {
        accessorKey: "reportid",
        header: "Report ID",
        enableColumnFilter: false,
        // columnFilterModeOptions: defaultStringFilterModes,
        // filterFn: "equals",
        size: 200,
      },
    ],
    [environments]
  );

  function handleApplyFilter() {
    let columnOperators = table.getState().columnFilterFns;

    var new_filter = {};
    for (let column of columnFilters) {
      let columnId = column.id;
      let columnOperator = columnOperators[columnId];
      let columnValue = column["value"];
      if (columnValue.length > 1) {
        let vals = columnValue.length;
        let counter = 0;
        for (let val of columnValue) {
          if (val == null) {
            counter += 1;
          }
        }
        if (vals == counter) {
          continue;
        }
      }
      new_filter[`${columnId}`] = {
        operator: columnOperator,
        value: columnValue,
      };
    }
    setJsonFilter(new_filter);
    setForceRender((value) => value + 1);
  }

  async function fetchActivity(reload) {
    console.log(variables);
    if (jsonFilter.envid !== undefined && jsonFilter.envid != null) {
      try {
        const authToken = (
          await fetchAuthSession()
        ).tokens?.idToken?.toString();
        const restOperation = get({
          apiName: "powerbiapi",
          path: "/reportembedactivity",
          options: {
            headers: {
              Authorization: authToken,
            },
            queryParams: variables,
          },
        });
        const response = await restOperation.response;
        console.log("GET call succeeded: ", response);
        const body = await response.body.json();
        console.log(body);
        if (response["statusCode"] != 200) {
          throw new Error(body);
        }

        const itemsFromAPI = body["Items"];
        var totalCount = 0;

        if (reload) {
          setActivity(itemsFromAPI);
          totalCount = itemsFromAPI.length;
        } else {
          setActivity(activity.concat(itemsFromAPI));
          totalCount = activity.length + itemsFromAPI.length;
        }

        // if (itemsFromAPI.length == 0) {
        //   setIsLoading(false);
        // }
        if (!("LastEvaluatedKey" in body)) {
          setNextApiToken(null);
          setIsLoading(false);
        } else {
          if (totalCount >= MAX_RECORDS_LIMIT) {
            setIsLoading(false);
            setNextApiToken(null);
            alert(
              "Reached limit of maximum number of records:" + MAX_RECORDS_LIMIT
            );
          } else {
            var newToken = JSON.stringify(body["LastEvaluatedKey"]);
            setNextApiToken(encodeURIComponent(newToken));
          }
        }
      } catch (error) {
        console.log(
          "Error while fetching Activity" + error.response.body.toString()
        );
        setIsLoading(false);
        alert("Error while fetching Activity" + error.response.body.toString());
      }
    }
  }

  async function fetchEnvironments() {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const restOperation = get({
        apiName: "powerbiapi",
        path: "/environments",
        options: {
          headers: {
            Authorization: authToken,
          },
        },
      });
      const response = await restOperation.response;
      console.log("GET call succeeded: ", response);
      const body = await response.body.json();
      console.log(body);
      if (response["statusCode"] != 200) {
        throw new Error(body);
      }

      const itemsFromAPI = body["Items"];
      setEnvironments(
        itemsFromAPI.map(function (item) {
          return { label: item.customeralias, value: item.id };
        })
      );
    } catch (error) {
      console.log("Error while fetching Environments", error);
    }
  }

  useEffect(() => {
    if (jsonFilter.envid !== undefined && jsonFilter.envid != null) {
      setIsLoading(true);
      setActivity([]);
      fetchActivity(true);
    } else {
      setIsLoading(false);
      fetchEnvironments();
    }
  }, [forceRender]);

  useEffect(() => {
    if (nextApiToken !== undefined && nextApiToken !== null) {
      fetchActivity(false);
    }
    //eslint-disable-next-line
  }, [nextApiToken]);

  const table = useMaterialReactTable({
    columns,
    data: activity,
    enableFilterMatchHighlighting: false,
    enableColumnFilterModes: true,
    // enableColumnResizing: true,
    enableColumnOrdering: true,
    enableFacetedValues: true,
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      density: "compact",
      showColumnFilters: true,
      columnVisibility: {
        requestid: false,
        workspaceid: false,
        username: false,
        reportid: false,
      },
      pagination: {
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    },
    sortDescFirst: false,
    muiPaginationProps: {
      rowsPerPageOptions: PAGE_SIZE_OPTIONS,
    },
    state: {
      isLoading,
      columnFilters,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        <Button
          color="secondary"
          onClick={() => {
            handleApplyFilter();
          }}
          variant="contained"
        >
          Apply Filter
        </Button>
      </Box>
    ),
  });

  // console.log(table.getState());
  // console.log(table.getState().columnFilters[2].value[0]);

  return (
    <div className="activity">
      <Box>
        <Typography variant="h4" align="center" padding={3} color="darkblue">
          Report Embed Activity
        </Typography>
        <MaterialReactTable table={table} />
      </Box>
    </div>
  );
};

const ReportEmbedActivity = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ReportEmbedActivityTable />
  </LocalizationProvider>
);

export default ReportEmbedActivity;
