import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  RECORDS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
  MAX_RECORDS_LIMIT,
  DEFAULT_PAGE_SIZE,
} from "../regoutil";
import { get } from "aws-amplify/api";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import EnvironmentCreate from "./EnvironmentCreate";
import EnvironmentEdit from "./EnvironmentEdit";

const EnvironmentTable = () => {
  const [environments, setEnvironments] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState([]);
  const [nextApiToken, setNextApiToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [forceRender, setForceRender] = useState(0);

  var variables = {
    limit: RECORDS_PER_PAGE,
    nextToken: nextApiToken,
  };

  const defaultStringFilterModes = [
    "equals",
    "contains",
    "startsWith",
    "endsWith",
    "empty",
    "notEmpty",
  ];
  const defaultNumberFilterModes = [
    "equals",
    "betweenInclusive",
    "empty",
    "notEmpty",
    "greaterThan",
    "greaterThanOrEqualTo",
    "lessThan",
    "lessThanOrEqualTo",
  ];
  const defaultDateFilterModes = [
    "equals",
    "betweenInclusive",
    "empty",
    "notEmpty",
    "greaterThan",
    "greaterThanOrEqualTo",
    "lessThan",
    "lessThanOrEqualTo",
  ];

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Environment ID",
        columnFilterModeOptions: defaultStringFilterModes,
        filterFn: "equals",
        size: 150,
      },
      {
        accessorKey: "customeralias",
        header: "Name",
        columnFilterModeOptions: defaultStringFilterModes,
        filterFn: "contains",
        size: 150,
      },
      {
        accessorKey: "domainname",
        header: "Domain",
        columnFilterModeOptions: defaultStringFilterModes,
        filterFn: "contains",
        size: 300,
      },
      {
        accessorFn: (row) => (row.active ? "Yes" : "No"),
        id: "active",
        header: "Active",
        filterVariant: "select",
        filterSelectOptions: ["Yes", "No"],
        filterFn: "equals",
        size: 150,
      },
      {
        accessorKey: "pbiworkspaceid",
        header: "Power BI Workspace ID",
        columnFilterModeOptions: defaultStringFilterModes,
        filterFn: "contains",
        size: 300,
      },
      {
        accessorKey: "maxrunsperday",
        header: "Max Runs Per Day",
        columnFilterModeOptions: defaultNumberFilterModes,
        filterFn: "betweenInclusive",
        size: 150,
      },
      {
        accessorKey: "maxrunsperhour",
        header: "Max Runs Per Hour",
        columnFilterModeOptions: defaultNumberFilterModes,
        filterFn: "betweenInclusive",
        size: 150,
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "createdAt",
        header: "Created",
        Cell: ({ cell }) =>
          `${cell.getValue().toLocaleDateString()} ${cell
            .getValue()
            .toLocaleTimeString()}`,
        filterVariant: "datetime-range",
        size: 600,
      },
      {
        accessorFn: (row) => new Date(row.updatedAt),
        id: "updatedAt",
        header: "Updated",
        Cell: ({ cell }) =>
          `${cell.getValue().toLocaleDateString()} ${cell
            .getValue()
            .toLocaleTimeString()}`,
        filterVariant: "datetime-range",
        size: 600,
      },
    ],
    []
  );

  async function fetchEnvironments(reload) {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const restOperation = get({
        apiName: "powerbiapi",
        path: "/environments",
        options: {
          headers: {
            Authorization: authToken,
          },
          queryParams: variables,
        },
      });
      const response = await restOperation.response;
      console.log("GET call succeeded: ", response);
      const body = await response.body.json();
      console.log(body);
      if (response["statusCode"] != 200) {
        throw new Error(body);
      }

      const itemsFromAPI = body["Items"];
      var totalCount = 0;
      if (reload) {
        setEnvironments(itemsFromAPI);
        totalCount = itemsFromAPI.length;
      } else {
        setEnvironments(environments.concat(itemsFromAPI));
        totalCount = environments.length + itemsFromAPI.length;
      }

      if (itemsFromAPI.length == 0) {
        setIsLoading(false);
      }
      if (!("LastEvaluatedKey" in body)) {
        setNextApiToken(null);
        setIsLoading(false);
      } else {
        if (totalCount >= MAX_RECORDS_LIMIT) {
          setIsLoading(false);
          setNextApiToken(null);
          alert(
            "Reached limit of maximum number of records:" + MAX_RECORDS_LIMIT
          );
        } else {
          var newToken = JSON.stringify(body["LastEvaluatedKey"]);
          setNextApiToken(encodeURIComponent(newToken));
        }
      }
    } catch (error) {
      console.log("Error while fetching Environments", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchEnvironments(true);
  }, [forceRender]);

  useEffect(() => {
    if (nextApiToken !== undefined && nextApiToken !== null) {
      fetchEnvironments(false);
    }
    //eslint-disable-next-line
  }, [nextApiToken]);

  const resetPageHandler = async () => {
    setIsLoading(true);
    setNextApiToken(null);
    setForceRender((value) => value + 1);
  };

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = async () => setOpenCreateModal(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = (thisEnvironment) => {
    console.log(thisEnvironment);
    setSelectedEnvironment(thisEnvironment);
    setOpenEditModal(true);
  };
  const handleCloseEditModal = async () => setOpenEditModal(false);

  const table = useMaterialReactTable({
    columns,
    data: environments,
    enableColumnFilterModes: true,
    // enableColumnResizing: true,
    enableColumnOrdering: true,

    initialState: {
      density: "compact",
      columnFilters: [{ id: "active", value: "Yes" }],
      columnPinning: { left: ["mrt-row-actions"], right: [] },
      expanded: true,
      showColumnFilters: true,
      columnVisibility: { id: false, createdAt: false },
      pagination: {
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    },
    sortDescFirst: false,
    muiPaginationProps: {
      rowsPerPageOptions: PAGE_SIZE_OPTIONS,
    },
    state: {
      isLoading,
    },
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        <Button
          color="secondary"
          onClick={handleOpenCreateModal}
          variant="contained"
        >
          Create Environment
        </Button>
      </Box>
    ),
    renderRowActions: ({ row, staticRowIndex, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleOpenEditModal(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  // console.log(table.getState());
  // console.log(table.getState().columnFilters[2].value[0]);
  // console.log(table.getState().columnFilters[2].value[1]);

  return (
    <div className="environments">
      <Box>
        <Typography variant="h4" align="center" padding={3} color="darkblue">
          Manage Environments
        </Typography>
        <MaterialReactTable table={table} />
        <Modal
          open={openCreateModal}
          onClose={handleCloseCreateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <EnvironmentCreate
              forceRender={resetPageHandler}
              handleCloseModal={handleCloseCreateModal}
            />
          </div>
        </Modal>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <EnvironmentEdit
              selectedEnvironment={selectedEnvironment}
              forceRender={resetPageHandler}
              handleCloseModal={handleCloseEditModal}
            />
          </div>
        </Modal>
      </Box>
    </div>
  );
};

const Environments = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <EnvironmentTable />
  </LocalizationProvider>
);

export default Environments;
