import dayjs from "dayjs";
//This is used in pagination of API
export const RECORDS_PER_PAGE = 500;
export const MAX_RECORDS_LIMIT = 5000;

//UI options
export const PAGE_SIZE_OPTIONS = [100, 200, 500, 1000];
export const DEFAULT_PAGE_SIZE = 500;
export const EXPORT_CSV_DELIMITER = ",";

//constants

//to format date and time
export const formatDateTime = (string) => {
  // var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(string).toLocaleDateString([], options);
};

//to format date
export const formatDate = (string) => {
  if (string !== undefined && string !== null && string !== "") {
    var options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  } else {
    return null;
  }
};

//to convert boolean to readable status
export const getBooleanStatus = (val) => {
  if (!val) {
    return "No";
  } else {
    return "Yes";
  }
};

const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value) => {
  if (value !== null && value !== undefined) {
    const dateTime = new Date(value);
    const utcFromLocal = new Date(
      dateTime.getTime() + getTimezoneOffset(dateTime)
    );
    return utcFromLocal;
  } else {
    return value;
  }
};

export const localToUTC = (dayjsdateTime) => {
  if (dayjsdateTime !== null && dayjsdateTime !== undefined) {
    const jsdateTime = dayjs(dayjsdateTime).toDate();
    const utcFromLocal = new Date(
      jsdateTime.getTime() - getTimezoneOffset(jsdateTime)
    );
    return utcFromLocal;
  } else {
    return dayjsdateTime;
  }
};
