import React, { useState } from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { put } from "aws-amplify/api";
import {
  Button,
  Flex,
  TextAreaField,
  TextField,
  View,
  Input,
  CheckboxField,
  Label,
} from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { fetchAuthSession } from "aws-amplify/auth";

const EnvironmentEdit = (props) => {
  const id = props.selectedEnvironment.id;
  const [domainname, setDomainname] = useState(
    props.selectedEnvironment.domainname
  );
  const [pbiworkspaceid, setPbiworkspaceid] = useState(
    props.selectedEnvironment.pbiworkspaceid
  );
  const [customeralias, setCustomeralias] = useState(
    props.selectedEnvironment.customeralias
  );
  const [active, setActive] = useState(props.selectedEnvironment.active);
  const [maxrunsperhour, setMaxrunsperhour] = useState(
    props.selectedEnvironment.maxrunsperhour
  );
  const [maxrunsperday, setMaxrunsperday] = useState(
    props.selectedEnvironment.maxrunsperday
  );
  const [isSaving, setIsSaving] = useState(false);

  const thisEnvironment = {
    domainname,
    customeralias,
    pbiworkspaceid,
    active,
    maxrunsperhour,
    maxrunsperday,
  };

  async function UpdateEnvironment(event) {
    setIsSaving(true);
    event.preventDefault();
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const restOperation = put({
        apiName: "powerbiapi",
        path: "/environments",
        options: {
          headers: {
            Authorization: authToken,
          },
          queryParams: { id: id },
          body: thisEnvironment,
        },
      });
      const response = await restOperation.response;
      console.log("PUT call succeeded: ", response);
    } catch (e) {
      console.log("PUT call failed: ", JSON.parse(e.response.body));
    }
    props.handleCloseModal();
    props.forceRender();
    event.target.reset();
    setIsSaving(false);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Edit Environment
      </Typography>
      <View as="form" margin="1rem 0" onSubmit={UpdateEnvironment}>
        <Flex direction="column" justifyContent="left">
          <TextField
            name="domainname"
            label="Domain"
            type="url"
            size="small"
            required
            value={domainname}
            onChange={(e) => setDomainname(e.target.value)}
          />
          <TextField
            name="pbiworkspaceid"
            label="Power BI Workspace ID"
            size="small"
            required
            value={pbiworkspaceid}
            onChange={(e) => setPbiworkspaceid(e.target.value)}
          />
          <Flex direction="row" justifyContent="left">
            <TextField
              name="customeralias"
              label="Name"
              size="small"
              required
              value={customeralias}
              onChange={(e) => setCustomeralias(e.target.value)}
            />
            <CheckboxField
              name="active"
              label="Is Active?"
              size="small"
              labelPosition="top"
              value="yes"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Flex>
          <Flex direction="row" justifyContent="left">
            <TextField
              name="maxrunsperhour"
              type="number"
              label="Max Runs Per Hour"
              size="small"
              required
              value={maxrunsperhour}
              onChange={(e) => setMaxrunsperhour(Number(e.target.value))}
            />
            <TextField
              name="maxrunsperday"
              type="number"
              label="Max Runs Per Day"
              size="small"
              required
              value={maxrunsperday}
              onChange={(e) => setMaxrunsperday(Number(e.target.value))}
            />
          </Flex>
        </Flex>

        <Flex direction="row" margin="1rem 0" justifyContent="center">
          <Button
            type="submit"
            variation="primary"
            size="small"
            width="20%"
            isLoading={isSaving}
          >
            Save
          </Button>
          <Button
            type="button"
            variation="warning"
            size="small"
            width="20%"
            // onClick={props.handleCloseModal}
            onClick={() => props.handleCloseModal()}
          >
            Close
          </Button>
        </Flex>
      </View>
    </Box>
  );
};

export default EnvironmentEdit;
