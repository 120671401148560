import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

const Logout = () => {
  const navigate = useNavigate();

  async function handleSignOut() {
    try {
      await signOut();
      navigate("/admin");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  useEffect(() => {
    handleSignOut();
    //eslint-disable-next-line
  }, [false]);

  return <div>Logging out....</div>;
};

export default Logout;
