import "./App.css";
import EmbedReport from "./components/EmbedReport";
import MainNav from "./components/MainNav";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

function App() {
  return (
    <Authenticator loginMechanisms={["email"]} hideSignUp variation="modal">
      {() => (
        <main>
          <MainNav />
        </main>
      )}
    </Authenticator>
    //<EmbedReport />
  );
}

export default App;
